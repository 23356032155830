import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import LandingPageLayout from "./layouts/LandingPageLayout";
import HomePage from "./pages/LandingPage/HomePage";
import AboutPage from "./pages/LandingPage/AboutPage";
import PartnerPage from "./pages/LandingPage/PartnerPage";
import BlogPage from "./pages/LandingPage/BlogPage";
import FormLayout from "./layouts/FormLayout";
import FormPesanPage from "./pages/FormPesan";
import FormDetailPage from "./pages/FormPesan/FormDetailPage";
import FormBayarPage from "./pages/FormPesan/FormBayarPage";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import { store, persistor } from './services/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import PrivateLayout from "./layouts/PrivateLayout";
import ProjectUsPage from "./pages/ProjectUsPage";
import DonatePage from "./pages/ProjectUsPage/DonatePage";
import NewsDetail from "./pages/ProjectUsPage/NewsPage";
import FormPaymentPage from "./pages/FormPayment";
import DashboardPage from "./pages/ProfilePage/DashboardPage";
import CampaignPage from "./pages/ProfilePage/CampaignPage";
import AddCampaign from "./pages/ProfilePage/CampaignPage/AddCampaign";
import CampaignDetailPage from "./pages/ProfilePage/CampaignPage/CampaignDetailPage";
import AddCampaignNews from "./pages/ProfilePage/CampaignPage/AddCampaignNews";
import EditCampaignNews from "./pages/ProfilePage/CampaignPage/EditCampaignNews";
import KonsultasiCSRPage from "./pages/LandingPage/ServicesPage/KonsultasiCSRPage";
import ManajemenProgramPage from "./pages/LandingPage/ServicesPage/ManajemenProgramPage";
import SocialImpactInvestmentPage from "./pages/LandingPage/ServicesPage/SocialImpactInvestmentPage";
import ContactUsPage from "./pages/LandingPage/ContactUsPage";
import BlogListPage from "./pages/ProfilePage/BlogPage";
import AddBlogPage from "./pages/ProfilePage/BlogPage/AddBlog"
import EditBlogPage from "./pages/ProfilePage/BlogPage/EditBlog"
import BlogDetailPage from "./pages/LandingPage/BlogPage/DetailBlog";

function App() {
  const [transactionCode, setTransactionCode] = useState("");
  const [title, setTitle] = useState("");

  return (
    <Wrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path={"/"} element={<LandingPageLayout />}>
              <Route index element={<HomePage />} />
              <Route path={"/about"} element={<AboutPage />} />
              <Route path={"/contact-us"} element={<ContactUsPage />} />
              <Route path={"/services"}>
                <Route
                  path={"/services/konsultasi-csr"}
                  element={<KonsultasiCSRPage />}
                />
                <Route
                  path={"/services/manajemen-program"}
                  element={<ManajemenProgramPage />}
                />
                <Route
                  path={"/services/social-impact-investment"}
                  element={<SocialImpactInvestmentPage />}
                />
              </Route>
              <Route path={"/portofolio"} element={<PartnerPage />} />
              <Route path={"/blog"}>
                <Route index element={<BlogPage />} />
                <Route
                  path={"/blog/detail/:slug"}
                  element={
                    <BlogDetailPage />
                  }
                />
              </Route>
              <Route path={"/project-us"}>
                <Route index element={<ProjectUsPage />} />
                <Route
                  path={"/project-us/donate/c:id"}
                  element={
                    <DonatePage />
                  }
                />
                <Route
                  path={"/project-us/donate/news"}
                  element={
                    <NewsDetail title={`title`} />
                  }
                />
              </Route>
              <Route path={"/login"} element={<LoginPage />} />
              <Route path={"/register"} element={<RegisterPage />} />
              <Route path={"/*"} element={<Navigate to="/" replace />} />
            </Route>
            <Route path={"/dashboard"} element={<PrivateLayout />} >
              <Route index element={<DashboardPage />} />
              <Route path={"/dashboard/campaign"} element={<CampaignPage />} />
              <Route path={"/dashboard/campaign/detail"} element={<CampaignDetailPage />} />
              <Route path={"/dashboard/campaign/add"} element={<AddCampaign />} />
              <Route path={"/dashboard/campaign/news/add"} element={<AddCampaignNews />} />
              <Route path={"/dashboard/campaign/news/edit"} element={<EditCampaignNews />} />
              <Route path={"/dashboard/blog"} element={<BlogListPage />} />
              <Route path={"/dashboard/blog/add"} element={<AddBlogPage />} />
              <Route path={"/dashboard/blog/edit"} element={<EditBlogPage />} />
              <Route path={"/dashboard/*"} element={<Navigate to="/dashboard" replace />} />
            </Route>
            <Route
              path={"/form"}
              element={<FormLayout transactionCode={transactionCode} />}
            >
              <Route index element={<FormPesanPage />} />
              <Route path={"/form/confirmation"} element={<FormDetailPage />} />
              <Route
                path={"/form/payment"}
                element={
                  <FormBayarPage setTransactionCodeValue={setTransactionCode} />
                }
              />
              <Route path={"/form/*"} element={<Navigate to="/form" replace />} />
            </Route>
            <Route
              path={"/payment"}
              element={<FormPaymentPage />}
            ></Route>
          </Routes>
        </PersistGate>
      </Provider>
    </Wrapper>
  );
}

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default App;
